import { Button, Container, Grid, Select, Text, Textarea, Title } from '@mantine/core';
import './FakeUserBio.scss';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect } from 'react';
import useAdminGetAllFakeUsers from '../../hooks/useAdminGetAllFakeUsers';
import useAdminUpdateUserInfo from '../../hooks/useAdminUpdateUserInfo';

const editBioScheme = z.object({
  userId: z.string().nonempty(),
  bio: z.string().nonempty(),
});

const FakeUserBio = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: users, isLoading: isUsersLoading, refetch } = useAdminGetAllFakeUsers();
  const { mutate: editBio, isLoading: isEditingBio } = useAdminUpdateUserInfo();

  const editBioForm = useForm({
    initialValues: {
      userId: '',
      bio: '',
    },
    validate: zodResolver(editBioScheme),
  });

  useEffect(() => {
    if (editBioForm.values.userId && users) {
      const user = users.find((user) => user.id === editBioForm.values.userId);
      if (user && user.bio) {
        editBioForm.setFieldValue('bio', user.bio);
      }
    }
  }, [editBioForm.values.userId, users]);

  const handleEditBio = (values: z.infer<typeof editBioScheme>): void => {
    editBio(values, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isUsersLoading && 'Loading...'}
          {!isUsersLoading && users && (
            <>
              <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)}>
                Back to Dashboard
              </Text>

              <form onSubmit={editBioForm.onSubmit(handleEditBio)}>
                <Title mt={'lg'}>Update bio</Title>
                <Select
                  data={users.map((user) => ({
                    value: user.id,
                    label: user.username,
                  }))}
                  label="Choose username"
                  mt={'md'}
                  {...editBioForm.getInputProps('userId')}
                />
                <Textarea
                  label="Bio"
                  placeholder="Enter bio"
                  minRows={5}
                  mt={'md'}
                  {...editBioForm.getInputProps('bio')}
                />
                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isEditingBio}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Save
                </Button>
              </form>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default FakeUserBio;
