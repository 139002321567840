import { notifications } from '@mantine/notifications';
import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminUpdateUserInfoKey } from '../api/apiReferencesContants';
import { ApiAdminUpdateUserInfo } from '../api/apiReferences';

export const getUpdateUserInfoKey = (): QueryKey => [ApiAdminUpdateUserInfoKey];

const useAdminUpdateUserInfo = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      bio: string;
      userId: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getUpdateUserInfoKey(),
    async (data: { bio: string; userId: string }) => {
      const res = await ApiAdminUpdateUserInfo(data);
      return res;
    },
    {
      onSuccess: () => {
        notifications.show({
          message: `Bio updated successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminUpdateUserInfo;
