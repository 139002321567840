import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiAdminGetAllFakeUsersKey } from '../api/apiReferencesContants';
import { User } from '../types';
import { ApiAdminGetAllFakeUsers } from '../api/apiReferences';

export const getAdminGetAllFakeUsersKey = (): QueryKey => [ApiAdminGetAllFakeUsersKey];

const useAdminGetAllFakeUsers = (): {
  isLoading: boolean;
  data?: User[];
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(getAdminGetAllFakeUsersKey(), async () => {
    const res = await ApiAdminGetAllFakeUsers();
    return res;
  });

  return {
    isLoading,
    data,
    refetch,
  };
};

export default useAdminGetAllFakeUsers;
